<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';

// icons
import { faTimes, faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltLines, faPhotoVideo, faUserCog, faSignOut } from '@fortawesome/pro-solid-svg-icons';

import userPlaceholderImage from '@/assets/svg/user_placeholder.svg?url';
import userPlaceholderImageGray from '@/assets/svg/user_placeholder.grey.svg?url';

/********************
 * COMPOSITIONS      *
 ********************/
const appStore = useAppStore();
const backendStore = useBackendStore();
const getRouteBaseName = useRouteBaseName();
const localePath = useLocalePath();
const route = useRoute();
const userStore = useUserStore();
const viewport = useViewport();
const { $gettext, $pgettext, interpolate } = useGettext();

/********************
 * PROPS & EMITS    *
 ********************/
const props = defineProps({
  lightTheme: { type: Boolean, default: false },
  size: { type: String, default: 'md' },
  withMenu: { type: Boolean, default: true },
});

/********************
 * REFS & VARS       *
 ********************/
const isMobile = computed(() => {
  return viewport.isLessThan('lg');
});

const userImage = computed(() => {
  return userStore.user?.profile?.images?.small
    ? fixImageUrl(backendStore.url, userStore.user?.profile?.images?.small)
    : '';
});

const i18nUserIconTitle = computed(() => {
  let msg = null;

  if (userStore.loggedIn && userStore.user.profile) {
    msg = $gettext('Logged in');
    const firstName = userStore.user.profile.first_name;
    const lastName = userStore.user.profile.last_name;

    if (firstName && lastName) {
      const msgKnown = $gettext('Logged in as %{name}');
      msg = interpolate(msgKnown, { name: `${firstName} ${lastName}` });
    }
  } else {
    msg = $gettext('Not logged in');
  }

  return msg;
});

/********************
 * WATCHER           *
 ********************/
watch(() => route.name, onRouteNameChange, { immediate: true });

/********************
 * FUNCTIONS         *
 ********************/
function onRouteNameChange() {
  appStore.userMenuOpen = false;
}

function onIconClicked(event: Event) {
  if (!props.withMenu) {
    return;
  }

  if (!appStore.userMenuOpen) {
    event.stopPropagation();
  }
  if (userStore.loggedIn) {
    appStore.userMenuOpen = true;
  } else {
    return navigateTo(
      localePath({
        name: RBN_LOGIN,
        query: { redirect: window.location.pathname },
      }),
    );
  }
}

async function onLogoutClicked() {
  await userStore.logout();
  const redirectPages = [
    'account',
    'account-change-mail',
    'account-change-password',
    RBN_ACCOUNT_MEDIA,
    RBN_ACCOUNT_MEDIA_DETAIL,
    RBN_ACCOUNT_PROFILE,
    RBN_ACCOUNT_REVIEWS,
  ];

  const baseName = getRouteBaseName(route);
  if (baseName && redirectPages.includes(baseName)) {
    return navigateTo(localePath({ name: 'index' }));
  } else {
    reloadNuxtApp();
  }
}

function onOutsideClicked() {
  if (appStore.userMenuOpen) {
    appStore.userMenuOpen = !appStore.userMenuOpen;
  }
}
</script>

<template>
  <div class="relative">
    <client-only>
      <div
        :title="i18nUserIconTitle"
        :class="`user-icon--${size}`"
        class="user-icon relative cursor-pointer"
        :data-testid="`ci-user-icon--user-menu-${props.withMenu}`"
        @click="onIconClicked"
      >
        <!-- user icon -->
        <div
          v-if="userStore.loggedIn && userStore.user.profile?.images?.small"
          :style="`background-image: url(${userImage})`"
          class="user-icon__image relative block rounded-full bg-cover bg-center"
        />
        <img
          v-if="
            (!userStore.loggedIn && props.lightTheme)
              || (userStore.loggedIn && !userStore.user.profile?.images?.small && props.lightTheme)
          "
          class="user-icon__image relative block rounded-full bg-cover bg-center"
          src="~/assets/svg/user_placeholder.svg"
          alt="user image placeholder"
          width="52"
          height="52"
        />
        <img
          v-if="
            (!userStore.loggedIn && !props.lightTheme)
              || (userStore.loggedIn && !userStore.user.profile?.images?.small && !props.lightTheme)
          "
          class="user-icon__image relative block rounded-full bg-cover bg-center"
          src="~/assets/svg/user_placeholder.grey.svg"
          alt="user image placeholder"
          width="52"
          height="52"
        />
        <div
          class="user-icon__indicator absolute border border-white"
          :class="[userStore.loggedIn ? 'bg-primary' : 'bg-danger']"
        />
      </div>
      <template #placeholder>
        <img
          class="user-icon__image relative block rounded-full bg-cover bg-center"
          :src="props.lightTheme ? userPlaceholderImage : userPlaceholderImageGray"
          alt="user image"
          width="52"
          height="52"
        />
      </template>
    </client-only>
    <transition name="user-menu-slide-fade">
      <div
        v-if="appStore.userMenuOpen && props.withMenu"
        class="user-icon__menu-wrapper fixed right-0 top-0 z-[988] h-screen max-h-screen w-[17.5rem] max-w-[80vw] overflow-auto rounded-none bg-white text-primary shadow-[0_0_1rem_rgba(0,0,0,0.5)] lg:absolute lg:h-auto lg:max-h-[calc(100vh-90px)] lg:w-[250px] lg:rounded-[5px]"
      >
        <div
          v-on-click-outside="onOutsideClicked"
          class="user-icon__menu h-100vh lg:h-auto"
        >
          <div class="border-b-[3px] border-primary bg-dark-green">
            <client-only>
              <div
                v-if="userStore.user.profile?.first_name"
                class="flex items-center p-4 text-white"
              >
                <span class="mr-3 truncate"><span class="mr-1">{{ $gettext('Hi') }}</span>{{ userStore.user.profile.first_name }}</span>
              </div>
            </client-only>
          </div>

          <ul class="m-0 flex list-none flex-col border-b border-gray-20 p-0 lg:border-0">
            <li class="nav-item">
              <nuxt-link
                :to="localePath({ name: RBN_ACCOUNT_REVIEWS })"
                class="nav-link flex items-center p-4 hover:no-underline lg:px-4 lg:py-2"
              >
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="faCommentAltLines"
                  :ratio="isMobile ? '0.9' : '0.7'"
                />
                <span class="ml-4 lg:ml-2">{{ $pgettext('Account navigation reviews', 'Reviews') }}</span>
              </nuxt-link>
            </li>
            <li class="nav-item">
              <nuxt-link
                :to="localePath({ name: RBN_ACCOUNT_MEDIA })"
                class="nav-link flex items-center p-4 hover:no-underline lg:px-4 lg:py-2"
              >
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="faPhotoVideo"
                  :ratio="isMobile ? '0.9' : '0.7'"
                />
                <span class="ml-4 lg:ml-2">{{
                  $pgettext('Account navigation pictures & videos', 'Pictures & Videos')
                }}</span>
              </nuxt-link>
            </li>
            <li class="nav-item">
              <nuxt-link
                :to="localePath({ name: RBN_FAVORITES })"
                class="nav-link flex items-center p-4 hover:no-underline lg:px-4 lg:py-2"
              >
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="faHeart"
                  :ratio="isMobile ? '0.9' : '0.7'"
                />
                <span class="ml-4 lg:ml-2">{{ $pgettext('Header Navigation Title', 'Bookmarks') }}</span>
              </nuxt-link>
            </li>
            <li class="nav-item">
              <nuxt-link
                :to="localePath({ name: RBN_ACCOUNT_PROFILE })"
                class="nav-link flex items-center p-4 hover:no-underline lg:px-4 lg:py-2"
              >
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="faUserCog"
                  :ratio="isMobile ? '0.9' : '0.7'"
                />
                <span class="ml-4 lg:ml-2">{{ $pgettext('Account navigation profile', 'Profile') }}</span>
              </nuxt-link>
            </li>
          </ul>

          <ul class="m-0 flex list-none flex-col border-b border-gray-20 p-0 lg:border-0">
            <li class="nav-item">
              <button
                class="button button--link nav-link flex w-full items-center justify-start p-4 !text-danger hover:!no-underline lg:px-4 lg:py-2"
                type="button"
                data-testid="ci-user-icon--logout"
                @click="onLogoutClicked"
              >
                <CiAwesomeIcon
                  class="!fill-danger"
                  :icon="faSignOut"
                  :ratio="isMobile ? '0.9' : '0.7'"
                />
                <span class="!ml-4 lg:!ml-2">{{ $pgettext('Header Navigation Title', 'Logout') }} </span>
              </button>
            </li>
          </ul>
        </div>

        <button
          v-if="appStore.userMenuOpen"
          class="button button--icon fixed right-[min(80vw,280px)] top-0 block h-[56px] lg:hidden"
          type="button"
          :aria-label="$gettext('Close')"
          data-cy="ci-user-menu-close"
          @click="appStore.userMenuOpen = false"
        >
          <CiAwesomeIcon
            class="fill-white"
            :icon="faTimes"
            ratio="1.2"
          />
        </button>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
$logoutButtonColor: darken($danger, 25%);
$userIconSize: 2rem;
$userIconSizeL: 3.25rem;
$userIconSizeSm: 1.5rem;

.user-icon {
  .user-icon__image {
    width: $userIconSize;
  }

  .user-icon__indicator {
    border-radius: 5px;
    height: 10px;
    right: 0;
    top: 0;
    width: 10px;
  }

  &.user-icon--sm {
    + .user-icon__menu-wrapper {
      @include media-breakpoint-up(lg) {
        top: $userIconSizeL * 1.75;
      }
    }

    .user-icon__image {
      width: $userIconSizeSm;
      height: $userIconSizeSm;
    }

    .user-icon__indicator {
      width: 6px;
      height: 6px;
    }
  }

  &.user-icon--md {
    .user-icon__image {
      width: $userIconSize;
      height: $userIconSize;
    }
  }

  &.user-icon--lg {
    + .user-icon__menu-wrapper {
      @include media-breakpoint-up(lg) {
        top: $userIconSizeL * 1.25;
      }
    }

    .user-icon__image {
      height: $userIconSizeL;
      width: $userIconSizeL;
    }

    .user-icon__indicator {
      border-radius: 6px;
      height: 12px;
      right: 2px;
      top: 2px;
      width: 12px;
    }
  }

  /* User icon menu */
  &__menu {
    -webkit-overflow-scrolling: touch;

    @include media-breakpoint-up(lg) {
      .nav-link {
        border-bottom: 1px solid $light-gray-20;

        .no-touch &:hover {
          background: $light-gray-20;
        }
      }
    }
  }
}

/* user menu transitions */
.user-menu-slide-fade-enter-active,
.user-menu-slide-fade-leave-active {
  transition: transform 0.4s ease-out;

  @include media-breakpoint-up(lg) {
    transform: translateY(0);
    transition:
      opacity 0.15s ease,
      transform 0.15s ease;
  }
}

.user-menu-slide-fade-enter-from,
.user-menu-slide-fade-leave-to {
  transform: translateX(90vw);
  // transition: transform 0.4s ease-out;

  @include media-breakpoint-up(lg) {
    transition:
      opacity 0.15s ease,
      transform 0.15s ease;
    transform: translateY(-1rem);
    opacity: 0;
  }
}
</style>
